<template>
  <header class="io-header">
    <h1 class="logo">
      <a class="logo-link" href="/">
        <img
          class="logo-img"
          :src="require(`@/assets/logo/logo-earnion-${theme}.png`)"
          alt="logo"
        />
      </a>
    </h1>
    <nav>
      <ul class="nav-list">
        <li class="app-download-btn">
          <AppDownloadBtn :theme="theme">
            <ToolTip />
          </AppDownloadBtn>
        </li>
        <li class="corp-inquiry">
          <a :class="`corp-link ${theme}`" @click="moveTo(5)"> 제휴 문의 </a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import AppDownloadBtn from "@/components/AppDownloadBtn.vue";
import ToolTip from "@/components/ToolTip.vue";

export default {
  components: { AppDownloadBtn, ToolTip },
  data() {
    return {
      theme: "white",
    };
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
  watch: {
    page(index) {
      if (index === 2 || index === 0) {
        this.theme = "white";
      } else {
        this.theme = "black";
      }
    },
  },
  methods: {
    moveTo(index) {
      this.$store.commit("setPage", index);
    },
  },
};
</script>

<style>
.io-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 3;
}

a.logo-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.nav-list {
  width: 310px;
  display: flex;
  justify-content: end;
  align-items: center;
  font-weight: bold;
}

ul.nav-list > li {
  cursor: pointer;
}

.corp-inquiry {
  display: none;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .io-header {
    height: 50px;
  }

  h1.logo {
    margin-left: 15px;
  }

  img.logo-img {
    width: 98px;
    height: 39px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .io-header {
    height: 76px;
  }

  h1.logo {
    margin-left: 41px;
  }

  img.logo-img {
    width: 150px;
    height: 60px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .io-header {
    height: 130px;
  }

  h1.logo {
    margin-left: 57px;
  }

  img.logo-img {
    width: 150px;
    height: 60px;
  }

  .corp-inquiry {
    display: block;
    margin-right: 46px;
  }

  a.corp-link {
    display: inline-block;
    width: 106px;
    height: 62px;
    line-height: 62px;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
  }
}
</style>
