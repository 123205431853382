<template>
  <section id="come">
    <article>
      <div class="come-content">
        <div class="come-txt">
          <h2 class="come-txt-title">
            가벼운 마음으로 <br class="mobile" />
            오세요 <span class="pc">🙌</span>
          </h2>
          <p class="come-txt-subtitle">
            부담스럽기만 했던 투자의 시작 <br />
            <span class="tab mobile"> 어니언과 함께해보세요. </span>
            <span class="pc"> 어니언과 함께라면 어렵지 않아요 </span>
          </p>
        </div>
      </div>
      <div class="bg">
        <video
          class="bg-video"
          autoplay
          muted
          playsinline
          :src="require(`@/assets/${device}/Vid_2-${device}.mp4`)"
          type="video/mp4"
          ref="comeVideo"
        >
          <p>Your browser does not support the video tag.</p>
        </video>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    device: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  watch: {
    page(page) {
      if (page === 1) {
        this.$refs.comeVideo.play();
      } else {
        this.$refs.comeVideo.pause();
        this.$refs.comeVideo.currentTime = 0;
      }
    },
  },
};
</script>

<style scoped>
#come {
  background: #fbfbfb;
}

#come article {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
}

.come-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: translate3d(0, 0, 1px); /* safari z-index issue */
}

.come-txt {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.come-txt-title {
  color: #1a2e35;
}

.come-txt-subtitle {
  color: #8c979a;
}

.mobile,
.tab,
.pc {
  display: none;
}

.bg {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;
}

.bg-video {
  width: inherit;
  height: inherit;
  z-index: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .come-content {
    margin-top: 92px;
    height: 246px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .come-txt {
    left: 50%;
    transform: translateX(-50%);
  }

  .come-txt-title {
    width: 300px;
    font-size: 30px;
    line-height: 42px;
    margin-bottom: 22px;
    text-align: center;
  }

  .come-txt-subtitle {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .mobile {
    display: inline;
  }

  .bg {
    height: calc(var(--vh) * 100 - 150px);
    padding-bottom: 60px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .come-content {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .come-txt {
    margin-top: 147px;
    left: 50%;
    transform: translateX(-50%);
  }

  .come-txt-title {
    display: inline-block;
    width: 450px;
    font-size: 45px;
    margin-bottom: 29px;
    text-align: center;
  }

  .come-txt-subtitle {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  .tab {
    display: inline;
  }

  .bg {
    width: 100%;
    height: calc(var(--vh) * 100 - 150px);
    padding-bottom: 80px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .come-content {
    justify-content: start;
    height: 170px;
  }

  .come-txt {
    margin: 160px 0 0 75px;
  }

  .come-txt-title {
    font-size: 50px;
    margin-bottom: 30px;
  }

  .come-txt-subtitle {
    font-size: 24px;
    line-height: 36px;
  }

  .pc {
    display: inline-block;
  }

  .mo-tab-txt {
    display: none;
  }

  .bg {
    height: calc(var(--vh) * 100 - 170px);
    justify-content: end;
    align-items: center;
  }
}
</style>
