import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  page: 0,
  device: "pc",
};

const mutations = {
  setPage(state, data) {
    state.page = data;
  },
  setDevice(state, data) {
    state.device = data;
  },
};

export default new Vuex.Store({
  state,
  mutations,
});
