<template>
  <section id="service">
    <div class="bg-gradient">
      <img class="bg-gradient-img" src="@/assets/pc/bg-gradient-pc.png" />
    </div>
    <article>
      <div class="service-container">
        <div class="service-content">
          <div class="service-content-title">
            다채로운 놀거리, <br />
            <strong>리워드로 투자까지</strong>
          </div>
          <ul class="service-content-list pc">
            <li>
              <a
                :class="`service-content-item
              ${serviceType === 'raffle' ? 'highlight' : 'grey'}`"
                @click="$emit('updateService', 'raffle')"
              >
                0원으로 주주되기 <br />
                <strong>어니언 래플</strong>
              </a>
            </li>
            <li>
              <a
                :class="`service-content-item
              ${serviceType === 'lotto' ? 'highlight' : 'grey'}`"
                @click="$emit('updateService', 'lotto')"
              >
                종목보는 눈이 높아지는 <br />
                <strong>어니언 로또</strong>
              </a>
            </li>
            <li>
              <a
                :class="`service-content-item
              ${serviceType === 'polio' ? 'highlight' : 'grey'}`"
                @click="$emit('updateService', 'polio')"
              >
                로또픽을 투자 포트폴리오로 <br />
                <strong>로또폴리오</strong>
              </a>
            </li>
          </ul>
        </div>
        <div class="service-img" ref="img">
          <img
            :class="serviceType === 'raffle' ? 'visible' : 'invisible'"
            src="@/assets/pc/raffle-img-pc.png"
            alt="service img"
          />
          <img
            :class="serviceType === 'lotto' ? 'visible' : 'invisible'"
            src="@/assets/pc/lotto-img-pc.png"
            alt="service img"
          />
          <img
            :class="serviceType === 'polio' ? 'visible' : 'invisible'"
            src="@/assets/pc/polio-img-pc.png"
            alt="service img"
          />
        </div>
        <a v-if="serviceType === 'raffle'" class="service-txt">
          0원으로 주주되기 <br />
          <strong>어니언 래플</strong>
        </a>
        <a v-else-if="serviceType === 'lotto'" class="service-txt">
          종목보는 눈이 높아지는 <br />
          <strong>어니언 로또</strong>
        </a>
        <a v-else class="service-txt">
          로또픽을 투자 포트폴리오로 <br />
          <strong>로또폴리오</strong>
        </a>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    serviceType: {
      type: String,
      default: "raffle",
    },
    vh: {
      type: Number,
    },
  },
  watch: {
    vh(vh) {
      this.setMargin(vh);
    },
  },
  mounted() {
    this.setMargin(this.vh);
  },
  methods: {
    setMargin(vh) {
      if (vh < 680) {
        this.$refs.img.style.cssText += `;margin: 0;`;
      } else {
        this.$refs.img.style.cssText += `;margin: 30px 0;`;
      }
    },
  },
};
</script>

<style scoped>
#service {
  background: #192f38;
}

#service article {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.service-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.service-txt {
  color: white;
  text-align: center;
}

.service-content-title {
  color: white;
  font-weight: lighter;
  text-align: center;
}

.grey {
  color: #c2cacc;
  display: none;
}

.service-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.service-img > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  transform: translate3d(0, 0, 1px); /* safari blink issue */
}

.visible {
  opacity: 1 !important;
}

.invisible {
  opacity: 0 !important;
}

.mobile,
.tab,
.pc {
  display: none;
}

.bg-gradient {
  display: none;
  width: 100%;
  height: 100%;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .mobile {
    display: block;
  }

  .service-content-title {
    font-size: 30px;
    line-height: 42px;
  }

  .service-img {
    min-width: 330px;
    min-height: 310px;
  }

  .service-txt {
    font-size: 16px;
    line-height: 28px;
    margin: 10px 0 65px 0;
  }

  .service-txt > strong {
    font-size: 20px;
  }

  .mini-margin {
    margin: 30px 0;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .tab {
    display: flex;
  }

  .service-img {
    max-width: 622px;
    max-height: 590px;
    width: 50vh;
    height: 50vh;
  }

  .service-content-title {
    font-size: 45px;
    line-height: 60px;
    margin: 0 0 57px 0;
  }

  .service-txt {
    font-size: 18px;
    line-height: 41px;
    margin: 45px 0 80px 0;
  }

  .service-txt > strong {
    font-size: 32px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  #service {
    background: rgb(22, 42, 50);
    background: linear-gradient(
      180deg,
      rgba(22, 42, 50, 1) 0%,
      rgba(24, 46, 54, 1) 20%,
      rgba(27, 51, 60, 1) 100%
    );
  }

  #service article {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .service-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-between;
  }

  .service-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    margin-left: 75px;
  }

  .service-content-title {
    font-size: 50px;
    line-height: 68px;
    margin-bottom: 105px;
    text-align: left;
  }

  .service-content-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    height: 385px;
  }

  .service-content-item {
    display: inline-block;
    font-size: 20px;
    line-height: 46px;
    cursor: pointer;
  }

  .service-content-item > strong {
    font-size: 36px;
  }

  .pc {
    display: flex;
  }

  .highlight {
    color: #00ffce;
  }

  .grey {
    display: inline-block;
  }

  .service-img {
    width: 810px;
    height: 776px;
  }

  .service-txt {
    display: none;
  }

  .bg-gradient {
    display: flex;
    justify-content: center;
    max-width: 1300px;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 0;
  }
}
</style>
