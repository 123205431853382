<template>
  <div class="pagination-container">
    <span
      :class="`bullet ${idx === page + 1 ? 'bullet-active' : ''}`"
      v-for="idx in pages"
      :key="idx"
    />
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      required: true,
    },
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
  },
};
</script>

<style>
.pagination-container {
  position: fixed;
  top: calc(var(--vh) * 50);
  transform: translateY(-50%);
  left: calc(50vw + 750px);
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.bullet {
  width: 10px;
  height: 10px;
  background: #c2cacc;
  margin: 0 0 5px 0;
  border-radius: 100%;
  transition: 0.3s;
}

.bullet-active {
  width: 10px;
  height: 51px;
  background: #00a88f;
  border-radius: 10px;
}
</style>
