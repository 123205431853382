export default (callback, delay) => {
  let timer = null;

  return (...args) => {
    if (!timer) {
      timer = setTimeout(() => {
        callback(...args);
        timer = null;
      }, delay);
    }
  };
};
