<template>
  <div class="btn-wrapper">
    <button :class="`bottom-btn ${theme}`">앱 다운로드</button>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style>
.btn-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}

.white {
  color: white;
}

.black {
  color: #1a2e35;
}

.bottom-btn {
  cursor: pointer;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background: #00a88f;
  font-weight: bold;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .btn-wrapper {
    height: 59px;
  }

  .bottom-btn {
    font-size: 16px;
    color: #fff;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .btn-wrapper {
    height: 80px;
  }

  .bottom-btn {
    font-size: 24px;
    color: #fff;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .btn-wrapper {
    position: relative;
    width: 127px;
    height: 62px;
  }

  .bottom-btn {
    font-size: 18px;
    background: none;
  }
}
</style>
