<template>
  <div id="container" class="fullpage-container">
    <TheHeader />
    <TheMain />
  </div>
</template>

<script>
import TheHeader from "../layouts/TheHeader.vue";
import TheMain from "../layouts/TheMain.vue";

export default {
  components: { TheHeader, TheMain },
};
</script>

<style>
.fullpage-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
