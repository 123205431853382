<template>
  <section id="cooperation" :style="customStyle">
    <article>
      <div class="cooperation-content">
        <h2 class="cooperation-content-title">
          비지니스도 <br class="pc mobile" />
          어니언과 함께해요
        </h2>

        <div class="cooperation-content-link">
          <a class="link" href="mailto:contact@indexmine.com" target="_blank">
            제휴 문의
          </a>
        </div>
      </div>
    </article>
    <TheFooter />
  </section>
</template>

<script>
const bg = {
  tab: `url(${require(`@/assets/tab/bg-img-tab.png`)})`,
  pc: `url(${require(`@/assets/pc/bg-img-pc.png`)})`,
};

import TheFooter from "@/layouts/TheFooter.vue";
export default {
  components: { TheFooter },
  computed: {
    customStyle() {
      return {
        "--tab": bg.tab,
        "--pc": bg.pc,
      };
    },
  },
};
</script>

<style scoped>
#cooperation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#cooperation > article {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.cooperation-content-title {
  color: #1a2e35;
  margin: 0 0 48px 0;
}

.cooperation-content-link {
  text-align: center;
  background: #1a2e35;
  border-radius: 10px;
}

.link {
  display: inline-block;
  color: white;
  width: 100%;
  height: 100%;
  font-weight: medium;
}

.mobile,
.tab,
.pc {
  display: none;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  #cooperation {
    background: #f9fafa;
    justify-content: space-between;
  }

  .cooperation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cooperation-content-title {
    font-size: 30px;
    margin: 120px 0 34px 0;
    text-align: center;
  }

  .cooperation-content-link {
    width: 120px;
    height: 40px;
  }

  .link {
    font-size: 14px;
    line-height: 40px;
  }

  .mobile {
    display: block;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  #cooperation {
    background: no-repeat var(--tab);
    background-color: #e4e6e7;
    background-size: 100%;
    background-position: center;
    justify-content: space-between;
  }

  .cooperation-content {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .cooperation-content-title {
    font-size: 45px;
    margin: 120px 0 48px 0;
  }

  .cooperation-content-link {
    width: 210px;
    height: 78px;
  }

  .link {
    font-size: 24px;
    line-height: 78px;
  }

  .tab {
    display: block;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  #cooperation {
    background: no-repeat var(--pc);
    background-size: cover;
    background-position: center;
  }

  #cooperation > article {
    justify-content: center;
  }

  .cooperation-content {
    margin: 0 0 0 65px;
  }

  .cooperation-content-title {
    font-size: 50px;
    margin: 0 0 48px 0;
  }

  .cooperation-content-link {
    width: 210px;
    height: 78px;
  }

  .link {
    font-size: 24px;
    line-height: 78px;
  }

  .pc {
    display: block;
  }
}
</style>
