<template>
  <div class="tooltip-container">
    <ul class="tooltip-wrapper">
      <li class="google-wrapper">
        <a
          class="google-link"
          href="https://play.google.com/store/apps/details?id=com.indexmine.earnion"
          target="_blank"
        >
          <img
            class="tooltip-icon"
            src="@/assets/icon/ic-google-play-tooltip.png"
            alt="google store"
          />
          <span class="tooltip-text">Google Play 다운로드</span>
        </a>
      </li>
      <li class="app-wrapper">
        <a
          class="app-link"
          href="https://apps.apple.com/kr/app/%EC%96%B4%EB%8B%88%EC%96%B8-earnion/id1591869412"
          target="_blank"
        >
          <img
            class="tooltip-icon"
            src="@/assets/icon/ic-app-store-tooltip.png"
            alt="apple store"
          />
          <span class="tooltip-text">App Store 다운로드</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.tooltip-container {
  position: fixed;
  width: 322px;
  height: 116px;
  background: white;
  border-radius: 10px;
  -webkit-box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px -3px rgba(29, 28, 28, 0.1);
  box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  z-index: 3;
}

.btn-wrapper:hover .tooltip-container {
  visibility: visible;
}

.bottom-btn:active .tooltip-container {
  visibility: visible;
}

.tooltip-container::before {
  content: "";
  position: absolute;
  background: white;
}

ul.tooltip-wrapper {
  position: absolute;
  background: white;
  border-radius: 10px;
  padding: 22px 0 22px 34px;
}

.google-wrapper {
  margin-bottom: 20px;
}

.google-link,
.app-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #4c565a;
}

.tooltip-icon {
  width: 26px;
  height: 26px;
  margin-right: 6px;
}

.tooltip-text {
  font-size: 20px;
  font-weight: medium;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .tooltip-container {
    width: 272px;
    height: 98px;
    bottom: 88px;
    transform: translateX(calc(50vw - 136px));
  }

  .tooltip-container::before {
    bottom: -3px;
    left: 124px;
    width: 23px;
    height: 23px;
    transform: rotate(45deg) skew(13deg, 13deg);
    -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 6px 0px rgba(29, 28, 28, 0.1);
    box-shadow: 1px 1px 6px 0px rgba(58, 46, 46, 0.1);
  }

  ul.tooltip-wrapper {
    padding: 19px 0 19px 29px;
  }

  .google-wrapper {
    margin-bottom: 16px;
  }

  .tooltip-icon {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  .tooltip-text {
    font-size: 16px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .tooltip-container {
    bottom: 113px;
    transform: translateX(calc(50vw - 161px));
  }

  .tooltip-container::before {
    bottom: -3px;
    left: 146px;
    width: 27px;
    height: 27px;
    transform: rotate(45deg) skew(12deg, 12deg);
    -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 6px 0px rgba(29, 28, 28, 0.1);
    box-shadow: 1px 1px 6px 0px rgba(58, 46, 46, 0.1);
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .tooltip-container {
    top: 95px;
    right: 50px;
  }

  .tooltip-container::before {
    top: -5px;
    left: 150px;
    width: 27px;
    height: 27px;
    transform: rotate(45deg) skew(12deg, 12deg);
    -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 1px 6px 0px rgba(29, 28, 28, 0.1);
    box-shadow: 1px 1px 6px 0px rgba(58, 46, 46, 0.1);
  }
}
</style>
