<template>
  <section id="main">
    <div class="bg">
      <video
        class="bg-video"
        :src="require(`@/assets/${device}/Vid_1-${device}.mp4`)"
        autoplay
        muted
        playsinline
        type="video/mp4"
        ref="mainVideo"
      >
        <p>Your browser does not support the video tag.</p>
      </video>
    </div>
    <article>
      <div class="main-content">
        <div class="main-title">
          <h2 class="main-title-txt">모든 투자의 시작</h2>
          <VueLottiePlayer
            class="main-lottie"
            :animationData="require(`@/assets/lottie/earning_on.json`)"
            width=""
            height=""
            @animControl="lottieControl"
          />
        </div>
        <div class="main-app">
          <a
            class="main-google-link"
            href="https://play.google.com/store/apps/details?id=com.indexmine.earnion"
            target="_blank"
          >
            <img
              class="main-app-icon"
              src="@/assets/icon/ic-google-play.png"
              alt="google store"
            />
            <span class="main-app-txt">Google Play</span>
          </a>
          <a
            class="main-app-link"
            href="https://apps.apple.com/kr/app/%EC%96%B4%EB%8B%88%EC%96%B8-earnion/id1591869412"
            target="_blank"
          >
            <img
              class="main-app-icon"
              src="@/assets/icon/ic-app-store.png"
              alt="apple store"
            />
            <span class="main-app-txt">App Store</span>
          </a>
        </div>
        <div class="main-scroll">
          <img
            class="main-scroll-img"
            src="@/assets/icon/ic-scroll.png"
            alt="scroll-img"
          />
          <span class="main-scroll-txt">SCROLL DOWN</span>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import VueLottiePlayer from "vue-lottie-player";

export default {
  components: { VueLottiePlayer },
  data() {
    return {
      lottie: null,
    };
  },
  props: {
    device: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  watch: {
    page(page) {
      if (page === 0) {
        this.$refs.mainVideo.play();
        this.lottie.goToAndPlay(0);
      } else {
        this.$refs.mainVideo.pause();
        this.$refs.mainVideo.currentTime = 0;
      }
    },
  },
  methods: {
    lottieControl(anim) {
      this.lottie = anim;
    },
  },
};
</script>

<style scoped>
#main {
  background: #000;
}

.bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#main article {
  max-width: 1300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  transform: translate3d(0, 0, 1px); /* safari blink issue */
}

.main-title-txt {
  color: white;
}

.main-app {
  display: flex;
  justify-content: space-between;
  align-items: space-between;
}

.main-google-link,
.main-app-link {
  border: 1px solid #c2cacc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-app-txt {
  color: #eceeee;
}

.main-scroll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.main-scroll-txt {
  color: #8c979a;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .main-content {
    justify-content: center;
  }

  .main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 66px 0;
  }

  .main-title-txt {
    font-size: 18px;
    margin-bottom: 6px;
  }

  .main-lottie {
    width: 360px;
    height: 74px;
    z-index: 3;
  }

  .main-app {
    width: 170px;
    height: 94px;
    flex-direction: column;
  }

  .main-google-link,
  .main-app-link {
    width: 170px;
    height: 43px;
  }

  .main-app-icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }

  .main-app-txt {
    font-size: 14px;
  }

  .main-scroll {
    width: 70px;
    height: 63px;
    position: relative;
    margin: 67px 0 0 0;
  }

  .main-scroll-img {
    width: 23px;
    height: 42px;
    margin-bottom: 8px;
  }

  .main-scroll-txt {
    width: 70px;
    font-size: 10px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .tab {
    display: block;
  }

  .main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 156px 0;
  }

  .main-title-txt {
    font-size: 18px;
    margin-bottom: 11px;
  }

  .main-lottie {
    width: 800px;
    height: 162px;
  }

  .main-app {
    width: 471px;
    height: 60px;
  }

  .main-google-link {
    width: 240px;
    height: 60px;
    margin-right: 11px;
  }

  .main-app-link {
    width: 220px;
    height: 60px;
  }

  .main-app-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .main-app-txt {
    font-size: 19px;
  }

  .main-scroll {
    width: 112px;
    height: 103px;
    bottom: 132px;
  }

  .main-scroll-img {
    width: 39px;
    height: 66px;
    margin-bottom: 16px;
  }

  .main-scroll-txt {
    width: 112px;
    font-size: 16px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .pc {
    display: block;
  }

  .main-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 90px 0;
  }

  .main-title-txt {
    font-size: 36px;
    margin-bottom: 2px;
  }

  .main-lottie {
    width: 1080px;
    height: 220px;
  }

  .main-app {
    width: 444px;
    height: 70px;
  }

  .main-google-link {
    width: 230px;
    height: 70px;
    margin-right: 16px;
  }

  .main-app-link {
    width: 198px;
    height: 70px;
  }

  .main-app-icon {
    width: 30px;
    height: 30px;
    margin-right: 7px;
  }

  .main-app-txt {
    font-size: 24px;
  }

  .main-scroll {
    width: 112px;
    height: 103px;
    position: absolute;
    bottom: 20px;
  }

  .main-scroll-img {
    width: 39px;
    height: 66px;
    margin-bottom: 16px;
  }

  .main-scroll-txt {
    width: 112px;
    font-size: 16px;
  }
}
</style>
