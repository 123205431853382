<template>
  <section id="investment">
    <article>
      <div class="investment-content">
        <h2 class="investment-content-title">
          어니언과 함께 <br />
          한걸음 더 투자에 <br class="mobile" />
          가까워져요
        </h2>
      </div>
      <div class="video-wrapper">
        <video
          :src="require(`@/assets/${device}/vid_7-${device}.mp4`)"
          autoplay
          muted
          playsinline
          type="video/mp4"
          ref="investVideo"
        >
          <p>Your browser does not support the video tag.</p>
        </video>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    device: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  watch: {
    page(page) {
      if (page === 4) {
        this.$refs.investVideo.play();
      } else {
        this.$refs.investVideo.pause();
        this.$refs.investVideo.currentTime = 0;
      }
    },
  },
};
</script>

<style scoped>
#investment {
  background: #f0f7fc;
}

#investment > article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.investment-content-title {
  color: #1a2e35;
  text-align: center;
}

.video-wrapper > video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  clip-path: inset(1px 1px);
  background: rgb(240, 247, 251);
}

.mobile,
.tab,
.pc {
  display: none;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  #investment {
    background: rgb(239, 246, 251) !important;
  }

  .mobile {
    display: block;
  }

  .investment-content {
    margin: 0 0 24px 0;
  }

  .investment-content-title {
    font-size: 30px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .tab {
    display: block;
  }

  .investment-content {
    margin: 0 0 64px 0;
  }

  .investment-content-title {
    font-size: 45px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .pc {
    display: block;
  }

  .investment-content {
    margin: 0 0 12px 0;
  }

  .investment-content-title {
    font-size: 50px;
  }
}
</style>
