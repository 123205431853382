<template>
  <main>
    <div
      ref="container"
      class="container"
      :style="`height: ${totalPage * vh}px;`"
    >
      <SectionOne :device="device" :page="curIndex" />
      <SectionTwo :device="device" :page="curIndex" />
      <SectionService
        v-if="device === 'pc'"
        :serviceType="service"
        @updateService="updateService"
      />
      <Swiper v-else :options="swiperOptions" class="swiper">
        <SwiperSlide>
          <SectionService serviceType="raffle" :vh="vh" />
        </SwiperSlide>
        <SwiperSlide>
          <SectionService serviceType="lotto" :vh="vh" />
        </SwiperSlide>
        <SwiperSlide>
          <SectionService serviceType="polio" :vh="vh" />
        </SwiperSlide>
        <div
          :class="`swiper-button-prev ${device === 'tab' ? 'show' : 'hide'}`"
          slot="button-prev"
        />
        <div
          :class="`swiper-button-next ${device === 'tab' ? 'show' : 'hide'}`"
          slot="button-next"
        />
        <div
          :class="`swiper-pagination ${device === 'mobile' ? 'show' : 'hide'}`"
          slot="pagination"
        />
      </Swiper>
      <SectionPower :device="device" :page="curIndex" />
      <SectionInvestment :device="device" :page="curIndex" />
      <SectionCooperation />
    </div>
    <ThePagination v-if="this.vw >= 1540" :pages="totalPage" />
  </main>
</template>

<script>
import throttle from "@/utils/throttle";
import debounce from "@/utils/debounce";
import ThePagination from "@/components/ThePagination.vue";
import SectionOne from "@/components/SectionOne.vue";
import SectionTwo from "@/components/SectionTwo.vue";
import SectionService from "@/components/SectionService.vue";
import SectionPower from "@/components/SectionPower.vue";
import SectionInvestment from "@/components/SectionInvestment.vue";
import SectionCooperation from "@/components/SectionCooperation.vue";

// Swiper
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    ThePagination,
    SectionOne,
    SectionTwo,
    SectionService,
    SectionPower,
    SectionInvestment,
    SectionCooperation,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      // fullpage options
      vw: null,
      vh: null,
      totalPage: 6,
      dir: "v",
      startX: null,
      startY: null,

      // fullpage event
      resize: null,
      scroll: null,
      touchStart: null,
      touchEnd: null,

      // swiper options
      swiperOptions: {
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        pagination: {
          el: ".swiper-pagination",
        },
        spaceBetween: 0, // swiper-slide 사이의 간격 지정
      },

      service: "raffle",
      canScroll: true,
    };
  },
  computed: {
    curIndex() {
      return this.$store.state.page;
    },
    curHeight() {
      return this.curIndex * this.vh;
    },
    device() {
      return this.vw < 800 ? "mobile" : this.vw < 1300 ? "tab" : "pc";
    },
  },
  created() {
    this.setViewport();
  },
  watch: {
    curIndex(idx) {
      this.moveTo(idx);
    },
    device: {
      immediate: true,
      handler(device) {
        this.$store.commit("setDevice", device);
      },
    },
  },
  mounted() {
    this.resize = throttle(() => {
      this.setViewport();
      this.moveTo(this.curIndex);
    }, 500);
    this.wheel = throttle(this.setScroll, 500);
    this.scroll = (e) => {
      if (this.canScroll) {
        this.canScroll = false;
        this.wheel(e);
      }
    };
    this.touchStart = debounce(this.setTouchStart, 100);
    this.touchEnd = debounce(this.setTouchEnd, 300);

    this.resize();
    window.addEventListener("touchstart", this.touchStart);
    window.addEventListener("touchend", this.touchEnd);
    window.addEventListener("mousewheel", this.scroll, false);
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("mousewheel", this.scroll);
    window.removeEventListener("touchstart", this.touchStart);
    window.removeEventListener("touchend", this.touchEnd);
  },
  methods: {
    setViewport() {
      this.vw = window.innerWidth;
      this.vh = window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${this.vh * 0.01}px`);
    },
    setScroll(e) {
      const detail = e.wheelDelta ? e.wheelDelta / 120 : e.detail * -1;
      const der = detail > 0 ? -1 : detail < 0 ? 1 : 0;
      const newIndex = der + this.curIndex;
      e.preventDefault();

      let timer;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.canScroll = true;
      }, 500);

      if (this.curIndex === 2 && this.device === "pc") {
        if (this.service === "lotto") {
          if (der === 1) {
            this.service = "polio";
          } else {
            this.service = "raffle";
          }
          return;
        } else if (this.service === "raffle") {
          if (der === 1) {
            this.service = "lotto";
            return;
          }
        } else if (this.service === "polio") {
          if (der === -1) {
            this.service = "lotto";
            return;
          }
        }
      }
      if (0 <= newIndex && newIndex < this.totalPage) {
        this.$store.commit("setPage", newIndex);
      }
    },
    setTouchStart(e) {
      this.startX = e.targetTouches[0].pageX;
      this.startY = e.targetTouches[0].pageY;
      e.preventDefault();
    },
    setTouchEnd(e) {
      const sub =
        this.dir === "v"
          ? (e.changedTouches[0].pageY - this.startY) / this.vh
          : (e.changedTouches[0].pageX - this.startX) / this.vw;
      const der = sub > 0.1 ? -1 : sub < -0.1 ? 1 : 0;
      const newIndex = der + this.curIndex;

      if (0 <= newIndex && newIndex < this.totalPage) {
        this.$store.commit("setPage", newIndex);
      }
      e.preventDefault();
    },
    moveTo(curIndex) {
      if (curIndex < 0 || curIndex >= this.totalPage) return;

      let x = 0;
      let y = 0;

      if (this.dir === "v") {
        y = curIndex * this.vh * -1;
      } else {
        x = curIndex * this.vw * -1;
      }

      this.$refs.container.style.cssText += `
      ;-webkit-transform : translate3d(${x}px, ${y}px, 0px);
      transform : translate3d(${x}px, ${y}px, 0px);
      `;
    },
    updateService(service) {
      this.service = service;
    },
  },
};
</script>

<style>
div.container {
  position: fixed;
  width: 100%;
  height: calc(var(--vh) * 100);
  transition: 0.5s ease;
}

div.container > section {
  width: 100%;
  height: calc(var(--vh) * 100);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transform: translate3d(0, 0, 0); /* safari blink issue */
}

section article {
  height: calc(var(--vh) * 100);
}

.swiper {
  background: #192f38;
  width: 100%;
  height: calc(var(--vh) * 100);
  transition: none;
}

.swiper-button-prev {
  color: #eceeee !important;
  margin-left: 60px;
  transform: translate3d(0, 0, 0) !important;
}

.swiper-button-next {
  color: #eceeee !important;
  margin-right: 60px;
  transform: translate3d(0, 0, 0) !important;
}

.swiper-pagination {
  margin-bottom: 65px;
  bottom: 10px;
  left: 50% !important;
  transform: translate3d(-50%, 0, 0) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 6px !important;
  height: 6px !important;
  background: #c2cacc !important;
  opacity: 1 !important;
  margin: 0 4px;
}

.swiper-pagination-bullet-active {
  width: 53px !important;
  background: #00a88f !important;
  border-radius: 10px !important;
}

.show {
  display: flex !important;
}

.hide {
  display: none !important;
}
</style>
