import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLottiePlayer from "vue-lottie-player";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

Vue.config.productionTip = false;
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  VueLottiePlayer,
  render: (h) => h(App),
}).$mount("#app");
