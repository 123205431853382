<template>
  <section id="power">
    <article>
      <div class="power-wrapper">
        <h2 class="power-txt"><span class="green">쌓인 힘</span>으로</h2>
        <div class="video-container">
          <video
            class="power-video"
            :src="require(`@/assets/${device}/VID_6-1-${device}.mp4`)"
            muted
            autoplay
            playsinline
            type="video/mp4"
            ref="powerVideo"
          >
            <p>Your browser does not support the video tag.</p>
          </video>
        </div>
      </div>
      <div class="stock-wrapper" ref="stock">
        <div class="power-txt-wrapper mobile">
          <h2 class="power-txt mobile">
            <span class="green">주식</span>을 얻어요
          </h2>
        </div>
        <div class="video-container">
          <video
            class="stock-video"
            :src="require(`@/assets/${device}/VID_6-2-${device}.mp4`)"
            muted
            autoplay
            playsinline
            type="video/mp4"
            ref="stockVideo"
          >
            <p>Your browser does not support the video tag.</p>
          </video>
        </div>
        <h2 class="power-txt pc tab">
          <span class="green">주식</span>을 얻어요
        </h2>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  props: {
    device: {
      type: String,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timeer: null,
    };
  },
  watch: {
    page(page) {
      if (page === 3) {
        this.addFadeUp();
        this.$refs.powerVideo.play();
        this.timer = setTimeout(() => {
          this.$refs.stockVideo.play();
        }, 1300);
      } else {
        this.removeFadeUp();
        clearTimeout(this.timer);
        this.$refs.powerVideo.pause();
        this.$refs.powerVideo.currentTime = 0;
        this.$refs.stockVideo.pause();
        this.$refs.stockVideo.currentTime = 0;
      }
    },
  },
  methods: {
    addFadeUp() {
      this.$refs.stock.classList.add("fade-up");
    },
    removeFadeUp() {
      this.$refs.stock.classList.remove("fade-up");
    },
  },
};
</script>

<style scoped>
#power {
  background: #fbfbfb;
}

#power > article {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-container {
  position: relative;
}

.video-container > video {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  clip-path: inset(1px 1px);
}

.power-wrapper,
.stock-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-wrapper {
  opacity: 0;
}

.power-txt {
  color: #1a2e35;
  font-weight: bold;
  z-index: 10;
  transform: translate3d(0, 0, 1px); /* safari z-index issue */
}

.green {
  color: #008571;
}

.mobile,
.tab,
.pc {
  display: none;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .mobile {
    display: block;
  }

  .video-container {
    width: 240px;
    height: 188px;
  }

  .power-wrapper,
  .stock-wrapper {
    display: flex;
    flex-direction: column;
  }

  .stock-wrapper {
    margin-top: 40px;
  }

  .power-video {
    left: 0px;
    top: 2px;
    width: 240px;
    height: 188px;
  }

  .stock-video {
    right: -20px;
    top: -70px;
    z-index: 1;
    width: 280px;
    height: 256px;
  }

  .power-txt-wrapper {
    position: relative;
    width: 196px;
    height: 43px;
    z-index: 10;
  }

  .power-txt .mobile {
    position: absolute;
  }

  .power-txt {
    font-size: 34px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .tab {
    display: block;
  }

  .video-container {
    width: 380px;
    height: 65px;
  }

  .stock-wrapper {
    margin-top: 233px;
  }

  .power-video {
    left: 40px;
    bottom: -103px;
  }

  .stock-video {
    right: -50px;
    top: -220px;
  }

  .power-txt {
    font-size: 52px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .pc {
    display: block;
  }

  .video-container {
    width: 580px;
    height: 85px;
  }

  .stock-wrapper {
    margin-top: 300px;
  }

  .power-video {
    left: 12px;
    bottom: -176px;
  }

  .stock-video {
    right: 42px;
    top: -300px;
  }

  .power-txt {
    font-size: 68px;
  }
}

.fade-up {
  animation: fade-up 0.5s linear 1s both;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
