<template>
  <footer class="io-footer">
    <div class="footer-inner">
      <div class="logo-address-wrapper">
        <a class="footer-logo" href="https://indexmine.com/">
          <img
            class="indexmine-logo"
            src="@/assets/logo/indexmine_01@2x.png"
            alt="indexmine logo"
          />
        </a>
        <address class="footer-address">
          <span class="bold"> 주식회사 인덱스마인 </span><br />
          사업자등록번호 : 170-88-00312 | 대표이사 : 박상우<br />
          <span class="space">
            서울시 영등포구 의사당대로83, 오투타워 4층 07325
          </span>
          <span class="newline"><br /></span>
          contact@indexmine.com<br />
          Copyright © Indexmine Co. Ltd All rights reserved
        </address>
      </div>
      <div class="sns-wrapper">
        <a
          class="instar-link"
          href="https://www.instagram.com/earnion_official/"
          target="_blank"
        >
          <img
            class="instar-img"
            src="@/assets/icon/icon-instar.png"
            alt="instar icon"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
.io-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  position: absolute;
}

.footer-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

address.footer-address {
  color: #505050;
  font-size: 12px;
  font-style: normal;
  line-height: 21px;
}

.logo-address-wrapper {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 113px;
  height: 66px;
  margin: 0 40px 0 0;
}

.indexmine-logo {
  width: 113px;
  height: 66px;
  margin: 0 40px 0 0;
}

.bold {
  font-weight: bold;
  font-size: 14px;
}

.space {
  margin-right: 14px;
}

.newline {
  display: none;
}

.instar-link {
  display: block;
  width: 33px;
  height: 33px;
  margin-top: 12px;
}

.instar-img {
  width: 100%;
}

/* 모바일 */
@media screen and (max-width: 799px) {
  .io-footer {
    height: 210px;
    background: #f3f3f3;
    bottom: 59px;
  }

  .footer-inner {
    padding: 26px 25px;
  }

  address.footer-address {
    line-height: 19px;
  }

  .logo-address-wrapper {
    display: block;
  }

  .footer-logo {
    width: 75px;
    height: 44px;
    margin: 0 0 16px 0;
  }

  .indexmine-logo {
    width: 75px;
    height: 44px;
    margin: 0 0 16px 0;
  }

  .bold {
    font-size: 12px;
  }

  .newline {
    display: inline;
  }

  .instar-link {
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 12px;
  }
}
/* 테블릿 */
@media screen and (min-width: 800px) and (max-width: 1299px) {
  .io-footer {
    bottom: 80px;
  }

  .footer-inner {
    padding: 0 40px 40px 40px;
  }
}
/* PC */
@media screen and (min-width: 1300px) {
  .io-footer {
    bottom: 0;
  }
  .footer-inner {
    padding: 0 65px 50px 65px;
  }
}
</style>
