<template>
  <router-view></router-view>
</template>

<script>
export default {};
</script>

<style>
* {
  font-family: "Spoqa Han Sans Neo", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

video {
  clip-path: inset(0);
  border: none;
  outline: none;
}

video:focus {
  outline: none;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  position: fixed;
  overflow: hidden;
  height: 100%;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-touch-callout: none;
  -ms-overflow-style: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  display: none;
}
</style>
